<template>
  <div class="auth">
    <div class="auth-content">
      <div class="side">
        <auth-side />
      </div>
      <div class="content">
        <div class="container">
          <div class="p-0 p-md-4">
            <div class="text-end mt-5 mt-md-0">
              <router-link :to="{ name: 'login' }" class="text-primary"
                ><strong>Go Back</strong></router-link
              >
            </div>
            <div class="text-center my-4 mobile-only">
              <router-link :to="{ name: 'home'}"><img src="/logo.svg" alt="logo"></router-link>
            </div>
            <div class="row mt-5">
              <div class="col-md-7">
                <div class="card shadow">
                  <div class="card-body">
                    <h3>Forgot Your Password?</h3>
                    <hr>
                    <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt quibusdam reiciendis!</p>
                    <form @submit.prevent="submit" id="forgot_password" v-if="! success">
                      <div class="form-floating mb-3">
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="Email"
                          name="email"
                          v-model="user.email"
                        />
                        <label for="email">Email</label>
                      </div>
                      <div>
                        <button class="btn btn-primary w-100 mt-3">
                          Send Code<i class="fa fa-sign-in ms-2"></i>
                        </button>
                      </div>
                    </form>
                    <div v-if="success">
                      <alert type="success">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis vero inventore enim eius! Facilis deserunt ipsum ratione veritatis accusamus autem!</p>
                      </alert>
                      <p><router-link :to="{ name: 'login' }" class="btn btn-primary w-100"><i class="fa fa-thumbs-up me-2"></i>Okay</router-link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthSide from "@/components/site/AuthSide";

export default {
  components: {
    AuthSide,
  },

  mounted() {
    if(this.$store.getters.getAuthUser.name != undefined) {
      this.$router.push({ name: 'dashboard' })
    }
  },

  data() {
    return {
      user: {},
      success: false
    }
  },

  methods: {
    submit() {
      this.success = false
      let data = this.user
      data.form_id = 'forgot_password'

      this.$axios.post('/api/v1/forgot-password', data).then(() => {
        this.success = true
      }).catch(() => {
        this.success = false
      })
    }
  }
};
</script>
